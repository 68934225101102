// overriding bootstrap variables in _boostrap-override.scss

$family-base: "proxima-nova",sans-serif;
$family-header: "proxima-nova",serif;

// font-size
$font-size-desktop: 21px;
$font-size-mobile: 16px;

$weight-light: 300;
$weight-normal: 300;
$weight-bold: 600;

// colors - sister colours from bootrap
$color-blue:    #007bff;
$color-red:     #c20f2f;
$color-orange:  #fd7e14;
$color-green:   #009345;
$color-light-green: #8bc53e;

// more colors
$color-gold:  #faa222;
$color-dark-gray: #252525;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;
$color-dark-grey: #454949;

// site colors
$primary-color: $color-green;
$font-color: $color-dark-grey;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color:white;
$link-color: $primary-color;
$link-hover-color: $color-light-green;

// top-bar
//$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 1.6rem, desktop: 2rem);
$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 2.7rem, small-device:2.8rem, tablet:3.5rem, desktop: 3.1rem);
$top-bar-height: (mobile: 44px, small-device:56px, tablet:56px, desktop: 56px);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;